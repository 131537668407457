<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <div v-if="state.isShowingBanner" class="bg-blue-900 text-white text-sm sm:text-base text-center flex justify-between py-2.5 px-4 font-semibold">
        <div />
        <div>Have a service or product to offer homeowners? <br class="sm:hidden" /> <router-link to="/servicers" class="font-bold text-blue-300">Get started here</router-link></div>
        <button @click="state.isShowingBanner = false" class="font-semibold focus:outline-none text-white">x</button>
      </div>

      <Menu :email="form.email" />
    </header>

    <!-- Hero -->
    <div class="w-full z-10 pt-32 pb-56" style="background: #e0ecfa;">
      <div class="max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4">
        <div class="grid md:grid-cols-8 gap-8 pt-20 pb-28">
          <div class="col-span-4 pt-8 flex flex-col gap-10 pr-4">
            <div class="font-bold text-4xl sm:text-6xl lg:text-7xl sm:leading-tighter text-blue-900">
              <div>Experience homeownership like a renter<sup class="px-1 text-xl" style="vertical-align: super;">&reg;</sup></div>
            </div>

            <div class="font-medium text-base sm:text-xl text-blue-900">
              Manage your home and family life in a single place, with personalized help when you need it. Create a home plan, connect with your community and put your home on autopilot. Welcome to modern homeownership!
            </div>
          </div>
            
          <div class="hidden md:block col-span-4">
            <img class="w-full" src="@/assets/LANDING PAGE HOME ICON.svg" alt="House illustration">
          </div>
        </div>
      </div>
    </div>


    <!-- Self manage & Concierge -->
    <div class="grid sm:grid-cols-2 gap-6 mx-auto max-w-5xl lg:max-w-6xl 2xl:max-w-8xl -my-56 mb-24 px-4">
      <div class="bg-white px-6 pt-8 pb-12 rounded-xl shadow-xl">
        <div class="font-semibold text-2xl text-hp-blue mb-8">Self Manage</div>
        <div class="font-bold text-3xl text-blue-900">I'm a homeowner</div>
        <div class="text-2xl text-hp-blue">looking to manage my own home, with occasional help</div>
        <button @click="getEarlyAccess" class="mt-8 py-3 px-4 flex items-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
          Get early access 
          <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
        </button>
      </div>

      <div class="bg-white px-6 pt-8 pb-12 rounded-xl shadow-xl">
        <div class="font-semibold text-2xl text-hp-blue mb-8">Concierge</div>
        <div class="font-bold text-3xl text-blue-900">I'm a homeowner</div>
        <div class="text-2xl text-hp-blue">looking to passively manage my home, with a home manager</div>
        <button @click="getEarlyAccess" class="mt-8 py-3 px-4 flex items-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
          Get early access 
          <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
        </button>
      </div>
    </div>



    <!-- Press -->
    <div>
      <div class="mb-4 text-center text-gray-400 font-medium">
        As Seen In
      </div>

      <div class="flex items-center gap-x-20 gap-y-8 flex-wrap justify-center max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 py-10">
        <a href="https://entrepreneurquarterly.com/st-louis-startup-founder-finds-traction-in-silicon-valley/" target="_blank">
          <img class="h-9 filter" src="@/assets/press/eq-yellow.png" alt="EQSTL logo">
        </a>

        <a href="https://words.seedscout.com/p/introducing-homeplan?s=r" target="_blank" class="text-gray-500 transition ease-in-out duration-200 font-semibold text-3xl">
          WORDS
        </a>

        <a href="https://podcasts.apple.com/us/podcast/hustling-from-homelessness-to-startup-success-with/id1562388323?i=1000556157303" target="_blank">
          <img class="h-6 filter" src="@/assets/press/growthhit.png" alt="GrowthHit logo">
        </a>
        
        <a href="https://www.spreaker.com/user/afm31/ep-hp-audio" target="_blank">
          <img class="h-10 rounded filter" src="@/assets/logos/afm.png" alt="AFM logo">
        </a>

        <a href="https://ftf.seedscout.com/809-bash-jones-homeplan-on-building-better-tools-for-homeowners/" target="_blank" class="transition ease-in-out duration-200 font-semibold text-3xl">
          <img class="h-14 p-1" src="@/assets/logos/seedscout.png" alt="Seedscout Logo">
        </a>

        <a href="https://share.transistor.fm/s/39970059" target="_blank" class="transition ease-in-out duration-200 font-semibold text-3xl">
          <img class="filter grayscale rounded h-11" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/founders-party.jpeg" alt="Founders Party Logo">
        </a>
      </div>
    </div>



    <!-- Separator -->
    <div  class="max-w-5xl mx-auto mt-8 flex justify-center">
      <img class="h-10 -mr-1" src="https://img.icons8.com/ios/100/1e3a8a/wavy-line.png"/>
      <img class="h-10" src="https://img.icons8.com/ios/100/1e3a8a/wavy-line.png"/>
    </div>



    <div class="max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 mt-24">
      <div class="text-center text-3xl sm:text-4xl font-bold mb-4 text-blue-900">Home Management, Made Simple<sup class="text-xl" style="vertical-align: super;">&reg;</sup></div>
      <div class="text-center text-2xl font-medium mb-16 text-blue-900">
        Homeowners want to own a home, but live like renters.
        Homeplan puts a home assistant, software and services in the pocket of every homeowner, ushering in a new era of more affordable, informed <br/> and delightful homeownership.
      </div>
    </div>


    <div class="homes-background fade-img">
      <div class="grid grid-cols-4 md:grid-cols-8 homes-background-grid">
        <div class="col-span-8" />
        <div class="col-span-8" />
        
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        
        <div class="md:hidden block" />

        <div data-aos="zoom-in-up" class="col-span-2 hidden md:block">
          <div class="flex">
            <span class="flex h-12 gap-4 items-center px-3 py-2 rounded-full shadow-lg font-semibold bg-blue-900 text-white">
              <img class="h-7 w-7 bg-white object-cover rounded-full" src="@/assets/testimonials/jane.png" alt="Jane photo">
              Roof claim paid
            </span>
          </div>
        </div>

        <div />

        <div class="md:col-span-1 col-span-2" data-aos="zoom-in-up">
          <span class="px-3 py-2 rounded-full shadow-lg font-semibold bg-blue-900 text-white">Saved $500</span>
        </div>

        <div />

        <div data-aos="zoom-in-up" class="col-span-2">
          <div class="flex">
            <span class="flex h-12 gap-4 items-center px-3 py-2 rounded-full shadow-lg font-semibold bg-blue-900 text-white">
              <img class="h-7 w-7 bg-white object-cover rounded-full" src="@/assets/testimonials/ceci.jpg" alt="Ceci photo">
              Home insurance secured
            </span>
          </div>
        </div>

        <div />
        <div />
        <div />
        <div />

        <div data-aos="zoom-in-up flex flex-col items-end justify-end">
          <span class="flex gap-4 items-center px-3 py-2 rounded-full shadow-lg font-semibold bg-blue-900 text-white">
            <img class="h-7 w-7 bg-white object-cover rounded-full" src="@/assets/testimonials/alex.png" alt="Alex photo">
            Saved $9,300
          </span>
        </div>

        <div />
        <div />
        <div />
        <div />

        <div class="md:hidden block" />
        <div class="md:hidden block" />

        <div class="col-span-2 md:col-span-1" data-aos="zoom-in-up">
          <span class="px-3 py-2 rounded-full shadow-lg font-semibold bg-blue-900 text-white">Groceries delivered</span>
        </div>

        <div class="md:hidden block" />


        <div data-aos="zoom-in-up" class="col-span-2 block md:hidden">
          <div class="flex">
            <span class="flex h-12 gap-4 items-center px-3 py-2 rounded-full shadow-lg font-semibold bg-blue-900 text-white">
              <img class="h-7 w-7 bg-white object-cover rounded-full" src="@/assets/testimonials/jane.png" alt="Jane photo">
              Roof claim paid
            </span>
          </div>
        </div>
        
      </div>
    </div>


    <!-- Case studies -->
    <div id="case-studies" class="max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 mt-36 mb-8">
      <div class="text-3xl sm:text-4xl text-center font-bold text-blue-900">Trusted by Homeowners</div>
    </div>

    <div class="pb-8 features-padding overflow-x-auto whitespace-nowrap hide-scrollbar">
      <CaseStudy 
        class="w-80 md:w-120"
        :before="require('@/assets/case-study/jane-before.jpg')"
        :after="require('@/assets/case-study/jane-after.jpg')"
        text="Homeowner filed a roof insurance claim, but didn't understand the claims process."
        slug="jane"
      />

      <CaseStudy 
        class="w-80 md:w-120"
        :before="require('@/assets/case-study/alex-before.jpg')"
        :after="require('@/assets/case-study/alex-after.jpg')"
        text="First time homebuyer needed new roof, gutters, soffit, fascia and mold removal."
        slug="alex"
      />

      <CaseStudy 
        class="w-80 md:w-120"
        :before="require('@/assets/case-study/sikina 1.jpg')"
        :after="require('@/assets/case-study/sikina 2.jpg')"
        text="A fire destroyed part of this homeowners rear deck, due to age."
        slug="sikina"
      />

      <CaseStudy
        class="w-80 md:w-120"
        :before="require('@/assets/case-study/cecilia.png')"
        :after="require('@/assets/case-study/cecilia.png')"
        text="Homeowner needed home insurance after 7 years of no coverage and a greenhouse teardown."
        slug="cecilia"
      />
    </div>



    <!-- Testimonials -->
    <div class="mt-12 mb-12 pt-16 pb-24 overflow-hidden">
      <div class="max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4">
        <div class="flex items-center flex-col gap-4">
          <span class="bg-blue-100 font-bold text-blue-900 py-1.5 px-2.5 rounded-xl text-sm mb-8">Testimonials</span>

          <div data-aos="fade-up" class="grid w-full sm:grid-cols-2 gap-6">
            <div class="w-full bg-white rounded-xl shadow-lg p-6">
              <div class="flex gap-4 items-center">
                <img class="h-12 w-12 object-cover border-1 rounded-full" src="@/assets/testimonials/jane.png" alt="Jane photo">
                <div class="flex flex-col">
                  <div class="text-xl sm:text-2xl font-semibold text-blue-900">Jane K.</div>
                  <div class="leading-5 text-hp-blue">Homeowner</div>
                </div>
              </div>

              <div class="mt-4 text-base sm:text-lg 2xl:text-xl text-blue-900">
                “I filed a roof insurance claim. Homeplan hired the roofing company on my behalf and fought for me, helping raise my initial claim from $12k to $53k.”
              </div>
            </div>

            <div data-aos="fade-up" class="w-full bg-white rounded-xl shadow-lg p-6">
              <div class="flex gap-4 items-center">
                <img class="h-12 w-12 object-cover border-1 rounded-full" src="@/assets/testimonials/alex.png" alt="Alex photo">
                <div class="flex flex-col">
                  <div class="text-xl sm:text-2xl font-semibold text-blue-900">Alex K.</div>
                  <div class="leading-5 text-hp-blue">Homeowner</div>
                </div>
              </div>

              <div class="mt-4 text-base sm:text-lg 2xl:text-xl text-blue-900">
                “Homeplan helped me purchase an affordable home for my daughter and I. When I needed a new roof, I used Homeplan and had a new one fully installed the next day.”
              </div>
            </div>

            <div data-aos="fade-up" class="w-full bg-white rounded-xl shadow-lg p-6">
              <div class="flex gap-4 items-center">
                <img class="h-12 w-12 object-cover border-1 rounded-full" src="@/assets/testimonials/devonta.png" alt="Devonta photo">
                <div class="flex flex-col">
                  <div class="text-xl sm:text-2xl font-semibold text-blue-900">Devonta B.</div>
                  <div class="leading-5 text-hp-blue">Homeowner</div>
                </div>
              </div>

              <div class="mt-4 text-base sm:text-lg 2xl:text-xl text-blue-900">
                “I like to stay organized. I recently purchased a home. Homeplan makes being a homeowner simple.”
              </div>
            </div>

            <div data-aos="fade-up" class="w-full bg-white rounded-xl shadow-lg p-6">
              <div class="flex gap-4 items-center">
                <img class="h-12 w-12 object-cover border-1 rounded-full" src="@/assets/testimonials/Verenice.jpg" alt="Verenice photo">
                
                <div class="flex flex-col">
                  <div class="text-xl sm:text-2xl font-semibold text-blue-900">Verenice C.</div>
                  <div class="leading-5 text-sm 2xl:text-base text-hp-blue">Daughter of Homeowner</div>
                </div>
              </div>

              <div class="mt-4 text-base sm:text-lg 2xl:text-xl text-blue-900">
                “My mom lives in a fire-prone area affected by wildfires. I used Homeplan to digitize her most important documents and give her peace of mind.”
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <!-- Features -->
    <div class="max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 mt-24 mb-16">
      <div class="text-center mb-3">
        <span class="bg-blue-100 font-bold text-blue-900 py-1.5 px-2.5 rounded-xl text-sm">Features</span>
      </div>

      <div class="text-center text-3xl sm:text-4xl font-bold text-blue-900 mb-4">Why Homeplan?</div>
      <div class="text-center text-2xl font-medium mb-16 text-blue-900">
        All-in-one home management, plus homeowner tools, personalized help, insights and maintenance,
        <br/> 
        under one roof to save you time, money & stress
      </div>
    </div>

    <div class="mb-8 features-padding py-4 overflow-x-auto whitespace-nowrap hide-scrollbar">
      <Feature color="1e3a8a" title="Home Concierge" description="Get a personal home assistant to manage your entire home." icon="https://img.icons8.com/external-ddara-fill-ddara/64/ffffff/external-customer-service-professions-ddara-fill-ddara.png" />
      
      <Feature color="A50AEE" title="Home Maintenance Tracker" description="Know when maintenance is due, scheduled and completed." icon="https://img.icons8.com/ios-filled/50/ffffff/wrench.png" />
      
      <Feature color="1e3a8a" title="Contractor Hiring Assistance" description="Leave it to Homeplan to hire trusted, vetted contractors to repair and maintain your home." icon="https://img.icons8.com/glyph-neue/64/ffffff/worker-male.png" />

      <Feature color="1e3a8a" title="Appliance Inventory" description="Keep track of all of your appliances and know when they're due for maintenance." icon="https://img.icons8.com/external-kmg-design-glyph-kmg-design/64/ffffff/external-refrigerator-electronics-device-kmg-design-glyph-kmg-design.png" />
      
      <Feature color="FC7F25" title="Home Insights" description="Get better insights into your home, neighborhood and equity." icon="https://img.icons8.com/ios-glyphs/60/ffffff/rebalance-portfolio.png" />
      
      <Feature color="35BAEA" title="Bill Pay" description="Automate and pay your household bills in one place. From mortgage payments to student loans." icon="https://img.icons8.com/ios-glyphs/60/ffffff/money--v1.png" />
      
      <Feature color="E02020" title="Tools" description="Use the calendar and to-do list, digitize documents and more to stay organized." icon="https://img.icons8.com/ios-glyphs/60/ffffff/calculator.png" />
      
      <Feature color="04B075" title="Homeowner Education" description="Learn about the hidden costs of homeownership and connect to industry experts in weekly Q&As." icon="https://img.icons8.com/ios-filled/50/ffffff/online-store.png" />
    </div>



    <!-- The Homeplan Process -->
    <div class="pt-36 pb-24 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4">
      <div class="text-center text-3xl sm:text-4xl font-bold text-blue-900 mb-4">The Homeplan Process</div>
      <div class="text-center text-2xl font-medium mb-16 text-blue-900">Software, services and trusted, affordable help for the modern homeowner</div>

      <div class="grid md:grid-cols-3 gap-16">
        <div class="flex flex-col gap-4">
          <img class="w-full px-28 py-12 object-contain md:px-48 md:py-16 lg:px-16 lg:py-12 mx-auto" src="@/assets/house.png" alt="">
          <div class="font-medium text-center text-lg 2xl:text-xl text-blue-900">
            Signup to homeplan (it's free), claim your home and choose to self manage or upgrade to a concierge plan.
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <img class="w-full px-28 py-12 object-contain md:px-48 md:py-16 lg:px-16 lg:py-12 mx-auto" src="@/assets/search.png" alt="">
          <div class="font-medium text-center text-lg 2xl:text-xl text-blue-900">
            Meet your home manager and get assistance setting up your accounts, subscriptions and dashboard.
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <img class="w-full px-28 py-12 object-contain md:px-48 md:py-16 lg:px-16 lg:py-12 mx-auto" src="@/assets/call-center.png" alt="">
          <div class="font-medium text-center text-lg 2xl:text-xl text-blue-900">
            Get monthly home reports and a check-in call to review your entire home.
          </div>
        </div>
      </div>
    </div>



    <!-- Blog -->
    <div class="pt-24 pb-24 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 flex flex-col items-center">
      <span class="bg-blue-100 text-center font-bold text-blue-900 py-1.5 px-2.5 rounded-xl text-sm">Blog</span>
      <div class="text-3xl sm:text-4xl text-center font-bold text-blue-900 mt-4 mb-16">Got home issues? Get help</div>

      <div class="grid md:grid-cols-3 gap-6">
        <Article 
          title="The Heavy Cost of Deferred Maintenance" 
          blurb="Not all maintenance is created equally, even if it all just looks like dollars and cents..."
          category="Deferred Maintenance"
          :image="require('../assets/blog/GettyImages-1043560698.jpg')"
        />

        <Article 
          title="Room by Room Home Improvement Projects" 
          blurb="With the realities of the Covid-19 pandemic and the ongoing work from home policies..."
          category="Home Improvement"
          image="https://images.unsplash.com/photo-1595814433015-e6f5ce69614e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        />

        <Article 
          title="How Deferred Maintenance Can Affect a Sale" 
          blurb="Not all maintenance is created equally, even if it all just looks like dollars and cents..."
          category="Deferred Maintenance"
          image="https://images.unsplash.com/photo-1607400201515-c2c41c07d307?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        />
      </div>
    </div>

    


    <!-- What our partners say -->
    <div class="pt-16 pb-20 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-12 md:px-4">
      <div class="text-center text-3xl sm:text-4xl font-bold text-blue-900 mb-12 sm:mb-16 relative" style="z-index: 5 !important;">What Our Partners Say</div>
      
      <div class="dotted-blue w-64 h-40 bg-red-500 absolute -ml-20 -mt-10 md:-mt-20" />

      <div class="bg-white rounded shadow-xl relative" style="z-index: 2 !important;">
        <div class="flex">
          <div class="flex items-center">
            <button @click="changePartnerTestimonial(-1)" class="bg-white shadow-lg border-2 border-blue-900 hover:bg-gray-100 transition duration-200 ease-in-out p-2 -ml-4 md:-ml-6 h-12 w-12 rounded-full focus:outline-none">
              <img src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/64/1e3a8a/external-left-arrow-kmg-design-glyph-kmg-design-1.png"/>
            </button>
          </div>

          <div class="py-6 md:py-12 px-2 md:p-16">
            <div class="text-lg md:text-xl leading-7 md:leading-8 text-center text-blue-900 mb-8">
              "{{ partners[state.partnersIndex].text }}"
            </div>

            <div class="flex justify-center gap-4 items-center text-blue-900">
              <img class="h-11 w-11 object-cover rounded-full shadow-md" :src="partners[state.partnersIndex].picture" :alt="`${partners[state.partnersIndex].author} Photo`">
              <div class="flex flex-col">
                <div class="text-lg font-semibold">{{ partners[state.partnersIndex].author }}</div>
                <div class="leading-5">{{ partners[state.partnersIndex].company }}</div>
              </div>
            </div>
          </div>

          <div class="flex items-center">
            <button @click="changePartnerTestimonial(1)" class="bg-white shadow-lg border-2 border-blue-900 hover:bg-gray-100 transition duration-200 ease-in-out p-2 -mr-4 md:-mr-6 h-12 w-12 rounded-full focus:outline-none">
              <img src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/64/1e3a8a/external-right-arrow-kmg-design-glyph-kmg-design-1.png"/>
            </button>
          </div>
        </div>

        <div class="bg-gray-100 px-6 flex items-center justify-center gap-4 py-6">
          <a class="rounded-xl overflow-hidden" target="_blank" href="https://www.firstintegrity.com/">
            <img class="gray-image h-8 2xl:h-12 object-contain" src="@/assets/logos/fims.png" alt="First Integrity Mortgage Logo">
          </a>

          <a target="_blank" href="https://www.sugarsecurity.com/" class="text-gray-500 py-2 px-4 text-xl text-center xl:text-2xl leading-5 font-bold">
            Sugar Security
          </a>

          <a class="rounded-xl overflow-hidden" target="_blank" href="https://www.seedscout.com/partnership">
            <img class="gray-image h-12 p-1 object-contain" src="@/assets/logos/seedscout.png" alt="Seedscout Logo">
          </a>
        </div>
      </div>

      <div class="flex justify-end">
        <div class="dotted-gray w-56 h-40 bg-red-500 absolute -mr-20 -mt-20" />
      </div>
    </div>




    <!-- Own more responsibly -->
    <div class="pt-20 pb-24 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4">
      <div class="text-center text-3xl sm:text-4xl font-bold text-blue-900 mb-8">Own More Responsibly</div>
      <div class="text-base sm:text-xl font-semibold text-center mb-3 sm:mb-12 text-blue-900">
        We built Homeplan to help homeowners like you simplify homeownership. By creating an all-in-one dashboard to manage every aspect of your home, we believe we can help save you time, money and stress. This allows you to get back to focusing on the things that matter most in your life. Through trusted, affordable help and education, our goal is to aid in creating better neighborhoods, ending blight and giving you the tools to manage and leverage your most valuable asset.
      </div>

      <div class="text-base sm:text-xl font-semibold text-center mb-8 sm:mb-12 text-blue-900">
        Homeplan is a Public Benefit Corporation. Our stated public benefit is: To create economic opportunity by proving trusted, affordable help to people in their everyday lives and in the use of our platform in efforts to close the economic gaps between communities.
      </div>

      
      <div class="flex justify-center gap-2">
        <img class="w-48 md:w-80 object-contain" src="@/assets/signature/s-sig.png" alt="Bash's signature">
        <img class="w-40 md:w-72 object-contain" src="@/assets/signature/a-sig.png" alt="Alex's signature">
      </div>
      <div class="text-center text-gray-500 font-semibold">
        - Bash and Alex
      </div>
      <div class="text-center text-gray-500 font-semibold">
        Homeplan founders
      </div>
    </div>


    
    <!-- Security & Data -->
    <div class="pt-20 pb-20 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4">
      <div class="text-center text-3xl sm:text-4xl font-bold text-blue-900 mb-12">Security & Data</div>

      <div class="text-blue-900 text-lg sm:text-xl sm:leading-8 items-center md:items-start md:text-left flex flex-col justify-center gap-4 font-medium">
        <div class="text-center">
          Homeplan takes privacy and security seriously. 
          We use Evervault to encrypt your data before it ever touches our servers. 
          Data is also encrypted in transit and at rest using SSL/TLS. 
          We partner with Sugar Security to run ongoing cybersecurity scans for any vulnerabilities in our apis. 
          Payments use real-time risk assessment for every payment, automatic KYC & AML verifications and bank grade encryption.
        </div>

        <div class="text-center w-full font-bold mb-16">
          Homeplan does not sell user data.
        </div>

        <div class="flex justify-center items-center gap-4 w-full">
          <a target="blank" href="https://evervault.com/?utm_source=badge">
            <img src="https://assets.evervault.com/img/evervault-badge-light.svg" alt="Encrypted by Evervault badge" width="151" height="48" />
          </a>

          <a target="blank" href="https://www.sugarsecurity.com/">
            <img class="border-1 rounded-lg" src="@/assets/sugar-security.png" alt="Scanned by Sugar Security badge" width="151" height="48">
          </a>

          <a target="blank" href="https://stripe.com/docs/security/stripe">
            <img src="@/assets/stripe-badge.svg" alt="Stripe badge" width="188" height="56">
          </a>
        </div>
      </div>
    </div>



    <!-- Get early access -->
    <div class="py-20">
      <div class="max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 flex flex-col gap-16 items-center">
        <div class="text-3xl font-semibold text-center text-blue-900">Ready to put your home on autopilot?</div>
          
        <div class="max-w-3xl mx-auto gap-4 flex flex-col sm:flex-row items-center sm:items-stretch cursor-text relative w-full">
          <input v-model="form.email" required id="ea-email-input" placeholder="Enter your email..." type="email" class="flex-1 w-full bg-hp-white shadow-lg border-gray-300 rounded outline-none duration-150 ease-in-out px-5 py-4 text-xl font-semibold">
          <div>
            <button @click="getEarlyAccess" class="h-full py-3 px-4 flex items-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Get early access 
              <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
            </button>
          </div>
        </div>
      </div>
    </div>



    <!-- FAQ Anchor -->
    <div id="faq"/>



    <!-- FAQ -->
    <div>
      <div class="max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 pt-28 pb-12">
        <div class="text-3xl sm:text-4xl text-center font-bold mb-6 text-blue-900">Frequently Asked Questions</div>

        <div>
          <button class="accordion text-lg sm:text-2xl">
            <div>What is Homeplan?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Homeplan is a home management app that pairs homeowners with software and a home assistant to digitally and automatically manage their homes.
            
            <br/><br/>
            
            Homeowners can track maintenance, repairs, appliances, insurance, documents, pay bills and handle all the most common tasks associated with home ownership, passively – moving away from time consuming hands-on processes like excel, text, phone calls, emails, lists and in-person appointments.
            
            <br/><br/>

            Our platform allows homeowners to experience homeownership like a renter, with the upside of equity and ownership for the more than 50% of homeowners who regret purchasing their home.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>What can I do with Homeplan?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            We've created various tools and a dashboard for homeowners. Please check out some of our features listed above.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>Is Homeplan a service company?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Homeplan is not a service company. We partner with service companies to help you take care of preventative and reactive maintenance, renovations and service subscriptions such as appliance repair, home warranty and lawn care (to name a few).
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>How much does Homeplan cost?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Homeplan is free to signup. No credit card is required. You can upgrade to concierge for a monthly or annual fee. Please check our <router-link class="font-semibold text-hp-blue" to="/pricing">pricing page</router-link>.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>How did Homeplan begin?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            We began building homeplan after becoming home owners and helping other home owners in our community with their home related problems over the years. Our journey started the moment we helped a retired veteran neighbor get a new roof after it rained in his home for nearly a year.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>I can't afford concierge, are there options for lower income home owners? </div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Yes, we provide a discount to lower income home owners. Contact us at <a class="font-semibold text-hp-blue" href="mailto:hello@joinhomeplan.com">hello@joinhomeplan.com</a> to learn more.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>Is my data secure?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Homeplan takes privacy and security seriously. We use Evervault to encrypt your data before it ever touches our servers. Data is also encrypted in transit and at rest using SSL/TLS. We've also partnered with Sugar Security to run continuous cybersecurity scans.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>Does Homeplan sell my data?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Homeplan does not sell user data.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>Who owns my data?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            You own any documents and/or photos you upload to Homeplan. Any publicly available information such as home facts is publicly available via your county assessors office and/or other public government sources. Please refer to our <router-link to="/privacy" class="font-semibold text-hp-blue">privacy</router-link> and <router-link to="/terms" class="font-semibold text-hp-blue">terms</router-link>  to learn more.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>How do you process payments?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Homeplan does not store nor process credit or debit card information. Subscription payments are processed via <a class="font-semibold text-hp-blue" target="_blank" href="https://stripe.com/docs/security/stripe">Stripe</a>. Stripe uses bank grade isolated encryption to process transactions.
            
            <br/> <br/>

            Homeplan partners with <a class="font-semibold text-hp-blue" target="_blank" href="https://plaid.com/">Plaid</a> to securely retrieve your account data. We partner with <a class="font-semibold text-hp-blue" target="_blank" href="https://methodfi.com/">MethodFi</a>, who handles the payment of your bills. Homeplan does not have direct access to your financial information and does not directly process payments.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>How do you access my financial data?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Homeplan partners with <a class="font-semibold text-hp-blue" target="_blank" href="https://plaid.com/">Plaid</a> to securely retrieve your account data. We partner with <a class="font-semibold text-hp-blue" target="_blank" href="https://methodfi.com/">MethodFi</a>, who handles the payment of your bills. Homeplan does not have direct access to your financial information and does not directly process payments.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>Are contractors vetted?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Contractors are required to have and maintain a license (where applicable), general liability and workers comp insurance and the owners of the business are background checked. Homeplan is not responsible for each business' employees. As a company, Homeplan believes in and practices fair chance employment.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>What is fair chance employment?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Fair chance employment is a way to improve our communities by eliminating barriers for those with a criminal record and creating a pathway for a second chance. Between 70-100 million Americans have a criminal record. We at Homeplan believe that a criminal record should not block a person from gainful employment. Companies like <a class="font-semibold text-hp-blue" target="_blank" href="https://checkr.com/">Checkr</a>, <a class="font-semibold text-hp-blue" target="_blank" href="https://slack.com/">Slack</a>, <a class="font-semibold text-hp-blue" target="_blank" href="https://www.honestjobs.co/">Honest Jobs</a>, <a class="font-semibold text-hp-blue" target="_blank" href="https://www.amazon.com/">Amazon</a>, <a class="font-semibold text-hp-blue" target="_blank" href="https://www.jpmorganchase.com/">JP Morgan Chase</a> and <a class="font-semibold text-hp-blue" target="_blank" href="https://www.starbucks.com/">Starbucks</a> practice fair chance employment. To learn more download <a class="font-semibold text-hp-blue" target="_blank" href="https://checkr.com/resources/ebook/fair-chance-hiring-report">Checkr's Fair Chance Report</a>.
          </div>

          <button class="accordion text-lg sm:text-2xl">
            <div>What is a Public Benefit Company?</div>
            <img src="https://img.icons8.com/ios-glyphs/30/1e3a8a/chevron-right.png"/>
          </button>
          <div class="panel sm:text-lg">
            Homeplan is a PBC (public benefit corporation). Public benefit corporations, also known as benefit corporations, are for-profit businesses whose charters commit them to social or environmental missions, not just maximizing shareholder value. These corporations take into account how other stakeholders such as communities and the environment will be affected by their actions. Examples of other PBC companies include <a href="https://www.allbirds.com/" class="font-semibold text-hp-blue" target="_blank">Allbirds</a>, <a href="https://www.lemonade.com/" class="font-semibold text-hp-blue" target="_blank">Lemonade Insurance</a>, <a href="https://www.patagonia.com/home/" class="font-semibold text-hp-blue" target="_blank">Patagonia</a> and <a href="https://www.ridewithloop.com/" class="font-semibold text-hp-blue" target="_blank">Loop Insurance</a>.
          </div>
        </div>

      </div>
    </div>



    <!-- Footer -->
    <img src="@/assets/people.png" alt="">

    <Footer />

  </div>
</template>

<script>
import Feature from "@/components/feature.vue";
import CaseStudy from "@/components/case-study.vue";
import Footer from "@/components/large-footer.vue";
import Article from "@/components/article.vue";

import Menu from "@/components/menu";
import AOS from 'aos';

import 'aos/dist/aos.css';

const axios = require("axios");

export default {
  components: {
    Menu,
    Feature,
    CaseStudy,
    Footer,
    Article
  },
  data() {
    return {
      state: {
        isShowingBanner: true,
        partnersIndex: 0
      },

      typewriter: {
        value: '',
        status: false,
        array: ['ownership,', 'management,', 'care,'],
        typingSpeed: 100,
        erasingSpeed: 100,
        newTextDelay: 500,
        arrayIndex: 0,
        charIndex: 0,
        textPause: 2000
      },

      form: {
        email: ''
      },

      partners: [
        {
          text: 'Homeplan seeks to educate homeowners on the nuances of owning a home, as well as connecting them with local experts in order to maximize and enrich their homeownership experience. Simply stated, Homeplan EDUCATES and CONNECTS.',
          author: 'Joshua Kemper',
          company: 'First Integrity Mortgage Services',
          picture: 'https://www.firstintegrity.com/uploads/sites/12289/public/josh.png'
        },

        {
          text: 'Homeplan is a needed product for so many homeowners. There is so much to think about when running a home but for some reason, no one has ever put that all into a simple app to manage it all. Until Homeplan. Excited to see where this product and team goes.',
          author: 'Mat Sherman',
          company: 'Seedscout',
          picture: 'https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/v1496014079/cflgqotasztdshyhzylr.jpg'
        },

        {
          text: 'Homeplan has invested in Cybersecurity from day one by building a secure app and Cybersecurity partnerships that ensure the homes managed by Homeplan are safe and private.',
          author: 'Logan Evans',
          company: 'Sugar Security',
          picture: require('@/assets/logan sugar security.png')
        }
      ]
    }
  },
  created() {
    if (this.$route.query.ref) {
      axios.post(`${window.api}/users/page_visit`, { ref: this.$route.query.ref, source: window.location.href });
    }

    setTimeout(this.typeText, this.typewriter.newTextDelay);
  },
  mounted() {
    AOS.init({ disable: 'mobile' });
    
    if (this.$route.hash == '#faq') {
      this.scrollTo('faq');
    }

    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  },
  methods: {
    getEarlyAccess() {
      if (this.form.email != '') {
        window.location = (`https://www.beta.joinhomeplan.com/?email=${this.form.email}`);
      } else {
        window.location = (`https://www.beta.joinhomeplan.com/`);
      }
    },

    scrollTo(id) {
      let e = document.getElementById(id);
      e.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start'
      });
    },

    typeText() {
      if (this.typewriter.charIndex < this.typewriter.array[this.typewriter.arrayIndex].length) {
        if (!this.typewriter.status)
          this.typewriter.status = true;

        this.typewriter.value += this.typewriter.array[this.typewriter.arrayIndex].charAt(this.typewriter.charIndex);
        this.typewriter.charIndex += 1;

        setTimeout(this.typeText, this.typewriter.typingSpeed);
      } else {
        this.typewriter.status = false;
        setTimeout(this.eraseText, this.typewriter.erasingSpeed + this.typewriter.textPause);
      }
    },

    eraseText() {
      if (this.typewriter.charIndex > 0) {
        if (!this.typewriter.status)
          this.typewriter.status = true;

        this.typewriter.value = this.typewriter.array[this.typewriter.arrayIndex].substring(0, this.typewriter.charIndex - 1);
        this.typewriter.charIndex -= 1;

        setTimeout(this.eraseText, this.typewriter.erasingSpeed);
      } else {
        this.typewriter.status = false;
        this.typewriter.arrayIndex += 1;

        if (this.typewriter.arrayIndex >= this.typewriter.array.length)
          this.typewriter.arrayIndex = 0;

          setTimeout(this.typeText, this.typewriter.typingSpeed + this.typewriter.newTextDelay);
      }
    },

    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    },

    changePartnerTestimonial(x) {
      if (this.state.partnersIndex + x < 0) {
        this.state.partnersIndex = this.partners.length - 1;
      } else if (this.state.partnersIndex + x >= this.partners.length) {
        this.state.partnersIndex = 0;
      } else {
        this.state.partnersIndex += x;
      }
    }
  }
}
</script>

<style scoped>
 @media only screen and (min-width: 1536px) {
    .features-padding {
      padding-left: calc((100vw - 88rem)/2);
      padding-right: calc((100vw - 88rem)/2);
    }
}


/* Up to 2xl */
@media only screen and (max-width: 1536px) {
    .features-padding {
      padding-left: calc((100vw - 72rem)/2);
      padding-right: calc((100vw - 72rem)/2);
    }
}


/* Up to xl */
@media only screen and (max-width: 1280px) {
    .features-padding {
      padding-left: 1rem;
      padding-right: 1rem;
    }
}




.dotted-blue {
  background-image: radial-gradient(#1e3a8a 20%, #FCFAFD 20%);
  background-size: 10px 10px;
  z-index: 1 !important;
}

.dotted-gray {
  background-image: radial-gradient(#e4e4e4 20%, #FCFAFD 20%);
  background-position: 0 0;
  background-size: 10px 10px;
  z-index: 1 !important;
}

.fade-img {
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,0));
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.homes-background {
  height: 100vh;
  background-size: cover;
  background-image: url("https://homeplan-assets.s3.us-east-2.amazonaws.com/houses-mountain-compressed.jpeg");
}

.homes-background-grid div {
  height: calc(100vh/6);
}

.case-study .case-study-before {
  display: block;
}

.case-study .case-study-after {
  display: none;
}

.case-study:hover .case-study-before {
  display: none;
}

.case-study:hover .case-study-after {
  display: block;
}

header {
  position: fixed;
  z-index: 1000;
}

.testimonials-section {
    background-color: #3b3c43;
    background-position: top 00px left 0px;
    background-size: cover;
    background-image: url("https://images.pexels.com/photos/277667/pexels-photo-277667.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
}

.accordion {
  @apply text-left flex items-center justify-between ease-out font-medium text-blue-900 outline-none border-none w-full cursor-pointer pt-6 pb-2 transition duration-200;
}

.accordion img {
  @apply transition transform ease-out duration-200;
}

.active, .accordion:hover {
  @apply text-blue-900;
}

.active img {
  @apply rotate-90;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  @apply text-blue-900;
}
</style>