<template>
  <div class="inline-block">
    <div @click="viewCaseStudy" class="case-study flex flex-col justify-between gap-12 hover:shadow-xl hover:bg-white cursor-pointer px-5 py-8 rounded-xl transition duration-200 ease-in-out">
      <div>
        <img class="case-study-before w-full h-80 rounded-lg object-cover" :src="before" alt="">
        <img class="case-study-after w-full h-80 rounded-lg object-cover" :src="after" alt="">

        <div class="mt-8">
          <div class="text-blue-300 font-semibold mb-2">Issue</div>

          <div class="h-24 font-medium text-lg text-blue-900 whitespace-normal">
            {{ text }}
          </div>
        </div>
      </div>
      
      <div>
        <button class="py-4 px-4 flex items-center gap-6 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
          view case study
          <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    before: String,
    after: String,
    text: String,
    slug: String
  },
  methods: {
    viewCaseStudy() {
      this.$router.push(`/case-studies/${this.slug}`)
    }
  }
}
</script>

<style scoped>
.case-study .case-study-before {
  display: block;
}

.case-study .case-study-after {
  display: none;
}

.case-study:hover .case-study-before {
  display: none;
}

.case-study:hover .case-study-after {
  display: block;
}
</style>